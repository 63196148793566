<template>
  <div style="justify-content: center; text-align:center; margin:0 auto;">
    <apexcharts
      id="multi-axis-linechart"
      type="line"
      height="420"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import formatNumber from '~/util/numeral';
import defaultTheme from '~/plugins/Theme/defaultTheme';

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    showDiff: {
      type: Boolean,
      required: false,
      default: false,
    },
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
    tooltip: {
      type: Array,
      required: false,
      default: () => [],
    },
    valueType: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      selectedLegendIndex: -1,
    };
  },
  computed: {
    colors() {
      const vm = this;
      const primaryColor = vm.$flipTheme?.theme?.primaryColor
        ? vm.$flipTheme.theme.primaryColor
        : defaultTheme.primaryColor;
      return [primaryColor, 'rgba(170,180,200,0.3)'];
    },
    series() {
      let payload = [];
      if (this.columns.length === 1) {
        if (this?.columns[0]?.currentDaysData) {
          payload.push({
            name: 'Current',
            type: 'line',
            data: this.columns[0].currentDaysData,
          });
        }
        if (this?.columns[0]?.prevDaysData) {
          payload.push({
            name: 'Previous',
            type: 'line',
            data: this.columns[0].prevDaysData,
          });
        }
        return payload;
      }
      payload = this.columns.map((c) => {
        return {
          name: c.legendName || '',
          type: 'line',
          data: c.currentDaysData,
        };
      });
      return payload;
    },
    chartOptions() {
      return {
        colors: this.chartColors,
        chart: {
          events: {
            mounted: () => {
              this.addLegendListeners();
            },
            updated: () => {
              this.addLegendListeners();
            },
          },
          height: 420,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
          lineCap: 'round',
        },
        grid: {
          show: false,
        },
        xaxis: {
          axisTicks: {
            show: true,
            color: '#7e8890',
          },
          axisBorder: {
            show: true,
            color: '#7e8890',
          },
          title: {
            style: {
              color: '#7e8890',
            },
          },
          categories: this.columns.length ? this.columns[0].currentDays : [],
          type: 'datetime',
          datetimeUTC: true,
          labels: {
            format: 'MM/dd/yyyy',
            style: {
              colors: '#7e8890',
              fontSize: '11px',
              fontFamily: 'Source Sans Pro',
            },
          },
          color: '#7e8890',
          tooltip: {
            enabled: true,
          },
        },
        yaxis: this.yaxis,
        tooltip: this.chartTooltip,
        legend: {
          horizontalAlign: 'center',
          position: 'bottom',
          height: 40,
          markers: {
            width: 10,
            height: 10,
            radius: 0,
          },
          itemMargin: {
            vertical: 10,
          },
        },
      };
    },
    chartTooltip() {
      return {
        followCursor: true,
        theme: false,
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const dataArr = this.tooltipData?.[dataPointIndex];
          // Adjust the date to match the x-axis timezone
          const tooltipHeaderDate = `${moment
            .utc(new Date(w.globals.seriesX[seriesIndex][dataPointIndex]))
            .format('MM-DD-yyyy')}`;

          let tooltipHTML = `<div class="apexchart-tooltip-base"><div class="${
            this.columns.length > 1 ? 'date-header' : 'no-date-header'
          }">${tooltipHeaderDate}</div>`;

          const itemsHTML = dataArr
            .map((dataObj, row) => {
              return `<div>
                  <div class="${this.columns.length > 1 ? 'tooltip-row' : dataObj?.meta?.css} "
                  ><div  class="${
                    this.columns.length > 1 ? 'square-legends' : 'no-square-legends'
                  }" style="background-color: ${
                this.columns.length > 1 ? `${this.columns?.[row]?.legendColor}` : 'transparent'
              }"></div>
                    ${dataObj?.data
                      ?.map((label, col) => {
                        return `<div class="tooltip-label tooltip-label-${row + 1}-${col +
                          1}">${label}</div>`;
                      })
                      .join('\n')}
                  </div>
                </div>`;
            })
            .join('\n');
          tooltipHTML += itemsHTML;
          tooltipHTML += '</div>';
          return tooltipHTML;
        },
      };
    },
    chartColors() {
      if (this.columns.length === 1) {
        return this.colors;
      }
      return this.columns.map((c) => c.legendColor);
    },
    yaxis() {
      const vm = this;
      let payload = [];
      if (this.columns.length === 1) {
        payload = [
          {
            show: this.selectedLegendIndex <= 0,
            axisTicks: {
              show: true,
              color: 'rgba(180, 180, 180, 0.2)',
            },
            axisBorder: {
              show: true,
              color: 'rgba(180, 180, 180, 0.2)',
            },
            labels: {
              show: this.selectedLegendIndex === 0,
              formatter: (d) => {
                if (d < 0) {
                  return '';
                }
                const str = formatNumber(d, this.columns[0].legendValueTypes, '0');
                return str.replace('.0', '');
              },
              style: {
                colors: vm.colors[0],
              },
              minWidth: 20,
              maxWidth: 20,
            },
          },
          // {
          //   show: this.selectedLegendIndex === 1,
          //   axisTicks: {
          //     show: true,
          //     color: 'rgba(180, 180, 180, 0.2)',
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: 'rgba(180, 180, 180, 0.2)',
          //   },
          //   labels: {
          //     formatter: (d) => {
          //       if (d < 0) {
          //         return '';
          //       }
          //       const str = formatNumber(d, this.columns[0].legendValueTypes, '0');
          //       return str.replace('.0', '');
          //     },
          //     style: {
          //       colors: vm.colors[1],
          //     },
          //     minWidth: 20,
          //     maxWidth: 20,
          //   },
          // },
        ];
      } else {
        payload = this.columns.map((c, ci) => {
          return {
            show: ci === 0 ? true : this.selectedLegendIndex === ci,
            axisTicks: {
              show: true,
              color: 'rgba(180, 180, 180, 0.2)',
            },
            axisBorder: {
              show: true,
              color: 'rgba(180, 180, 180, 0.2)',
            },
            labels: {
              show: this.selectedLegendIndex === ci,
              formatter: (d) => {
                if (d < 0) {
                  return '';
                }
                const str = formatNumber(d, c.legendValueTypes, '0');
                return str.replace('.0', '');
              },
              style: {
                colors: c.legendColor,
              },
              minWidth: ci === 0 ? 30 : 0,
              maxWidth: ci === 0 ? 30 : 0,
            },
          };
        });
      }
      return payload;
    },
    tooltipData() {
      if (this.columns.length > 1) {
        const tooltipArray = this.columns?.map?.((col) => col.tooltip);
        const dataToReturn = [];
        tooltipArray?.[0]?.forEach((_, index) => {
          const indexSpecificData = [];
          tooltipArray.forEach((tooltip, colIndex) => {
            const dataDataArr = [...(tooltip?.[index] || [])];
            const dataObj = { ...(dataDataArr[0] || {}) };
            dataObj.data = [...(dataObj.data || [])];
            dataObj.data[0] = this.columns?.[colIndex]?.legendName;
            const newDataObj = {
              data: [
                this.columns?.[colIndex]?.legendName,
                formatNumber(
                  this.columns?.[colIndex]?.currentDaysData?.[index],
                  this.columns?.[colIndex]?.legendValueTypes
                ),
              ],
              meta: dataObj.meta,
            };
            indexSpecificData.push(newDataObj);
          });
          dataToReturn.push(indexSpecificData);
        });
        return dataToReturn;
      }
      return this.columns?.[0]?.tooltip;
    },
  },

  mounted() {},

  methods: {
    addLegendListeners() {
      const elms = document.querySelectorAll('.apexcharts-legend-series');
      const elmsArr = Array.from(elms);
      elmsArr.forEach((elm) => {
        elm.addEventListener('mouseenter', this.handleLegendHover);
        elm.addEventListener('mouseleave', this.handleLegendLeave);
      });
    },
    handleLegendHover(e) {
      const elem = e.target;
      const index = elem?.getAttribute('rel');
      const otherLegendSeries = elem.parentElement.querySelectorAll('.apexcharts-legend-series');
      if (otherLegendSeries.length) {
        otherLegendSeries.forEach((ol) => {
          ol.style.opacity = 0.5;
        });
        elem.style.opacity = 1;
      }
      if (index) {
        this.selectedLegendIndex = +index - 1;
      }
    },
    handleLegendLeave(e) {
      if (e.target.classList.contains('apexcharts-legend-series')) {
        return;
      }
      this.selectedLegendIndex = -1;
    },
  },
};
</script>
<style lang="scss">
.chart-svg-wrapper {
  width: 100%;
}
.line-chart {
  width: 100%;
  height: 100%;
}
.ctooltip {
  // width: 180px;
  padding: 10px 15px;
  margin-top: -37px;
  font-size: 12px;
  text-align: left;
  background-color: #1a1b1d;
  border-radius: 4px;
  opacity: 0.96;
}

.lctt-wrap-header {
  margin-bottom: 10px;
  color: #eee;
}
.lctt-wrap-header > div:first-child {
  display: inline-block;
  width: 125px;
  padding-left: 5px;
}
.lctt-wrap-header > div:nth-child(2) {
  display: inline-block;
  width: 110px;
}
.lctt-wrap-header > div:nth-child(3) {
  display: inline-block;
  width: 110px;
}
.lctt-wrap-header > div:last-child {
  display: inline-block;
  float: right;
  width: 40px;
}

.lctt-wrap-type1 {
  color: var(--primarycolor);
}
.lctt-wrap-type1 > div:first-child {
  display: inline-block;
  width: 100px;
  padding-left: 5px;
}
.lctt-wrap-type1 > div:nth-child(n + 2) {
  display: inline-block;
  width: 100px;
  padding-right: 20px;
  text-align: right;
}
.lctt-wrap-type1 > div:last-child {
  display: inline-block;
  float: right;
  width: 100px;
  padding-right: 10px;
  text-align: right;
}

.lctt-wrap-compare {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #6d707b;
}
.lctt-wrap-compare > div:first-child {
  display: inline-block;
  width: 100px;
  padding-left: 5px;
}
.lctt-wrap-compare > div:nth-child(n + 2) {
  display: inline-block;
  width: 100px;
  padding-right: 20px;
  text-align: right;
}
.lctt-wrap-compare > div:last-child {
  display: inline-block;
  float: right;
  width: 100px;
  padding-right: 10px;
  text-align: right;
}

.lctt-wrap-change {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #eee;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.lctt-wrap-change > div:first-child {
  display: inline-block;
  width: 100px;
  padding-left: 5px;
  text-align: left;
}
.lctt-wrap-change > div:nth-child(n + 2) {
  display: inline-block;
  width: 100px;
  padding-right: 20px;
  text-align: right;
}
.lctt-wrap-change > div:last-child {
  display: inline-block;
  float: right;
  width: 100px;
  padding-right: 10px;
  text-align: right;
}

.lctt-wrap-change > div:not(:first-child) > span.negative-color-caret-down {
  color: var(--primaryred);
  display: flex;
  flex-direction: row;
  align-items: center;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid var(--primaryred);
    border-bottom: 0 solid transparent;
    margin-right: 3px;
  }
}

.lctt-wrap-change > div:not(:first-child) > span.negative-color-caret-up {
  color: var(--primaryred);
  display: flex;
  flex-direction: row;
  align-items: center;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid var(--primaryred);
    border-top: 0 solid transparent;
    margin-right: 3px;
  }
}
.lctt-wrap-change > div:not(:first-child) > span.positive-color-caret-up {
  color: var(--primarygreen);
  display: flex;
  flex-direction: row;
  align-items: center;
  &::before {
    width: 0;
    height: 0;
    content: '';
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid var(--primarygreen);
    border-top: 0 solid transparent;
    margin-right: 3px;
  }
}
.lctt-wrap-change > div:not(:first-child) > span.positive-color-caret-down {
  color: var(--primarygreen);
  display: flex;
  flex-direction: row;
  align-items: center;
  &::before {
    width: 0;
    height: 0;
    content: '';
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid var(--primarygreen);
    border-bottom: 0 solid transparent;
    margin-right: 3px;
  }
}

.lctt-wrap-change > div:not(:first-child) > span.zero {
  color: #cdd9e0;
}

.ctip-perc {
  display: inline-block;
}

.redFont {
  color: red !important;
}

.numNeg {
  color: red !important;
}

.numPos {
  color: green !important;
}

.hideChange {
  display: none;
}
.square-legends {
  width: 10px;
  height: 10px;
  min-width: 10px !important;
  min-height: 10px !important;
}
.no-square-legends {
  width: 0;
  height: 0;
  display: none;
}
.date-header {
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 13px !important;
  padding: 2px 5px;
  text-align: left;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  color: #fff;
  font-weight: 400;
}
.no-date-header {
  visibility: hidden;
  line-height: 8px;
}
.apexcharts-canvas text {
  color: #7e8890 !important;
}
.apexcharts-tooltip * {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px !important;
}
.apexcharts-legend-text,
.apexcharts-text tspan {
  color: #7e8890 !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 12px !important;
}
.light-theme .apexcharts-tooltip * {
  background-color: #f7f7f7 !important;
  .lctt-wrap-header-custom {
    color: #000 !important;
  }
}
.light-theme {
  .lctt-wrap-change {
    color: #9d9d9d;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .lctt-wrap-change > div:not(:first-child) > span.zero {
    color: #a5a6a7;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .apexcharts-legend.apx-legend-position-bottom {
  .apexcharts-legend-series {
    margin: 2px 0 !important;
    padding: 0 5px;
  }
}
::v-deep .tooltip-label {
  &:first-child {
    min-width: 120px !important;
    text-align: left !important;
  }
}
.apexcharts-tooltip * {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px !important;
}
::v-deep .apexcharts-tooltip {
  background-color: rgba(26, 27, 29, 0.96);
  box-shadow: none;
  div {
    font-size: 12px !important;
    width: auto !important;
  }
  div:nth-child(2) {
    min-width: 120px !important;
    text-align: left !important;
    width: unset !important;
  }
  div:last-child {
    min-width: 40px !important;
    text-align: right !important;
    width: unset !important;
  }
  div div:first-child {
    margin-right: 5px !important;
  }
  .apexchart-tooltip-base {
    .date-header {
      font-size: 13px !important;
      margin-right: 0 !important;
      color: #000 !important;
    }
    .lctt-wrap-header-custom {
      display: inline-flex !important;
      background-color: rgba(0, 0, 0, 0.1);
      font-size: 13px !important;
      padding: 2px 5px;
      text-align: left;
      border-left: 1px solid rgba(255, 255, 255, 0.05);
      color: #fff;
      font-weight: 400;
      margin-right: 0 !important;
      margin-top: -7px !important;
      margin-left: -1px;
      .tooltip-label:last-child {
        padding-right: 40px;
      }
    }
    .lctt-wrap-header-custom,
    .lctt-wrap-compare-custom,
    .lctt-wrap-change-custom,
    .lctt-wrap-custom {
      float: left !important;
      .no-square-legends {
        display: none !important;
      }
      .tooltip-label {
        width: 70px !important;
      }
      .tooltip-label:nth-child(2) {
        width: 70px !important;
        min-width: 70px !important;
        padding-left: 10px !important;
      }
      .tooltip-label:nth-child(3),
      .tooltip-label:nth-child(4) {
        min-width: 100px !important;
        width: auto !important;
        max-width: 125px !important;
      }
      .tooltip-label:last-child {
        min-width: 100px !important;
        width: auto !important;
        max-width: 125px !important;
      }
    }
    .lctt-wrap-custom {
      .no-square-legends {
        display: none !important;
      }
    }
  }
}
#multi-axis-linechart .apexcharts-tooltip.apexcharts-theme-light {
  padding: 10px 15px;
  font-size: 11px !important;
  text-align: left;
  background-color: rgba(26, 27, 29, 0.96);
  border-radius: 4px;
  color: #7e8890 !important;
}

::v-deep .apexcharts-tooltip {
  background-color: rgba(26, 27, 29, 0.96);
  box-shadow: none;
  padding: 0;
  .tooltip-row {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    min-width: 200px;
    padding: 3px 6px;
    color: #999;
  }
}

::v-deep .apexcharts-canvas {
  width: 100% !important;
  & > svg {
    width: 100%;
  }
}
</style>
